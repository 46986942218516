import { Center, Spinner } from "@efishery/onefish";

import { ILoadingProps } from "./type";

export function Loading({ container, spinner }: ILoadingProps) {
  return (
    <Center {...container}>
      <Spinner {...spinner} />
    </Center>
  );
}
